<!--
 * @Description: 主入口
 * @Author: zjc
 * @Date: 2021-12-07 11:45:22
 * @LastEditTime: 2023-11-17 16:17:39
 * @LastEditors: ym
-->
<template>
  <!-- <div id="app"> -->
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
  <!-- </div> -->
</template>
<script setup>
import { onMounted } from "vue";
import { setStore } from '@/utils/store'
import { setTheme } from '@/theme/theme.js'
onMounted(() => {
  //根据域名获取当前图片、样式包
  setTheme(window.location.host)
  window.addEventListener('beforeunload', () => {
    setStore({ name: 'pageNum', content: 1 })
  })
})
function noop () { }
setInterval(noop, 300000); //防止浏览器休眠导致页面无反应， 每5分钟调用一次
</script>
<style>
#app {
  width: 100%;
  min-height: 100%;
}
body {
  background: #fff;
  min-height: 100%;
  font-family: "微软雅黑";
  transition: opacity 0.3s !important;
  /* transform: translate3d(0, 0, 0); */
  /* -webkit-backface-visibility: hidden; */
  /* -webkit-perspective: 1000; */
}
</style>
