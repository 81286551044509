<!--
 * @Description: 
 * @Author: zjc
 * @Date: 2021-12-07 14:14:21
 * @LastEditTime: 2023-10-08 16:06:21
 * @LastEditors: ym
-->
<template>
  <div class="arcsin-contail" :class="{'arcsinCollapse':isCollapse}">
    <!-- 头部导航栏 -->
    <div class="arcsin-layout">
      <div class="arcsin-left" :style="{zIndex:contentScreen?90:subMenuList.legnth>0?0:100}"
        :class="{arcsinSub:subMenuList.length === 0}">
        <!-- <logo /> -->
        <!-- 左侧菜单 -->
        <navMenu />
      </div>
      <div class="arcsin-main arcsin-tabs" :class="{arcsinTabs1:subMenuList.length === 0}">
        <div :style="{minHeight:height}">
          <top />
          <mainContent />
        </div>
        <!-- <div id="el-main-footer">
          &copy; {{new Date().getFullYear()}} 正观易知出品
        </div> -->
      </div>
    </div>
  </div>

</template>
<script setup>
import mainContent from './components/main.vue'
import top from './components/top.vue'
import logo from './components/logo.vue'
import navMenu from './components/navMenu.vue'
import admin from '@/utils/admin'
import { SYSTEM_API } from '@/service/system/index.js'
import { commonStore } from '@/store/common.js'
import { adminStore } from '@/store/user.js'
import { COMMON_API } from '@/utils/common';
import { ref, computed, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { getStore, setStore } from '@/utils/store'
const loading = ref(false)
const height = ref(null)
const store = commonStore()
const useStore = adminStore()
const isCollapse = computed(() => store.collapse)
const contentScreen = computed(() => store.getContentScreen)
const subMenuList = computed(() => useStore.getMenuList)
const route = useRoute()
const initData = () => {
  store.SET_SCREEN(admin.getScreen())
  setTimeout(() => window.addEventListener('resize', initData), 10)
}
const getHeight = () => {
  height.value = window.innerHeight - 1 + 'px'
}
const getData = async () => {
  const data = await SYSTEM_API.getOrgInfo(useStore.getOrgInfo.orgId)
  useStore.setOrgInfo(data.data)
   if(getStore({ name: 'userInfo' })) {
      let userInfo = getStore({ name: 'userInfo' })
      useStore.getUserPermission(userInfo.member_id || '')
    }
}
watch(() => route, (val) => {
  getHeight()
})
onMounted(() => {
  getData()
  getHeight()
  initData()
})
</script>
<style lang="scss">
.arcsinSub {
  width: 0px !important;
  z-index: 101px !important;
}
.arcsinTabs1 {
  left: 0px !important;
  width: calc(100%) !important;
}
.arcsin-contail {
  width: 100%;
  min-height: 100vh;
  background: var(--content--background-color);
  background-size: 100%;
}

.arcsin-left {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  width: var(--navbar--spread-width);
  height: 100%;
  overflow: auto;
}

// $navbar--spread-width:208px;
// $navbar--packUp-width:60px;
.arcsin-main {
  position: relative;
  left: var(--navbar--spread-width);
  z-index: 100;
  box-sizing: border-box;
  width: calc(100% - 208px);
  // width: calc(100% - 80px);
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
.arcsinCollapse .arcsin-left,
.arcsinCollapse .arcsin-logo {
  width: $navbar--packUp-width;
}
.arcsinCollapse .arcsin-tabs,
.arcsinCollapse .arcsin-header {
  // left: $navbar--packUp-width;
  // width: calc(100% - 60px);
}
.arcsinCollapse .arcsin-main {
  left: $navbar--packUp-width;
  width: calc(100% - 80px);
}
.arcsinCollapse .arcsin-header {
  width: 100vw;
}

.arcsinCollapse .tabs {
  left: 60px;
  width: calc(100% - 60px);
}
#el-main-footer {
  overflow: hidden;
  height: 60px;
  line-height: 60px;
  color: #7a8b9a;
  letter-spacing: 0.8px;
  font-family: Arial, sans-serif !important;
  left: 50%;
  z-index: 99;
  width: 100%;
  font-size: 14px;
  text-align: center;
}
</style>