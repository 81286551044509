<!--
 * @Description: 
 * @Author: ym
 * @Date: 2023-06-16 15:53:21
 * @LastEditTime: 2023-09-13 11:26:14
 * @LastEditors: xlm
-->
<template>
  <div class="switch-tabs" v-if="dataList.length">
    <el-tabs v-model="activeId" type="card" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane v-for="item in dataList" :label="item.libraryName" :name="item.libraryId"
        :key="item.libraryId">
        <template #label>
          <span class="custom-tabs-label">
            <img
              :src="activeId === item.libraryId?getAssetUrl('icon/icon_library_sel.png'):getAssetUrl('icon/icon_library_nor.png')"
              alt="">
            <span>{{ item.libraryName }}</span>
          </span>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script setup>
import { toRefs, reactive, ref, onMounted, nextTick, computed, onBeforeUnmount, watch } from "vue"
const emit = defineEmits(['handleClick', 'update:modelValue', 'update:organizationName'])
import { SYSTEM_API } from '@/service/system/index.js'
import { getAssetUrl } from '@/theme/theme.js'
import { getStore, setStore, removeStore } from '@/utils/store'
import { adminStore } from '@/store/user.js'
const useStore = adminStore()
const props = defineProps({
  modelValue: {
    default: null
  },
  organizationName: String,
})

onMounted(() => {
  if (dataList.value && dataList.value.length) {
    state.activeId = getStore({ name: 'libraryId' }) ? getStore({ name: 'libraryId' }) : dataList.value[0].libraryId
    setStore({ name: 'libraryId', content: state.activeId })
    setStore({ name: 'libraryName', content: getStore({ name: 'libraryName' }) ? getStore({ name: 'libraryName' }) : dataList.value[0].libraryName })
    // emit("update:modelValue", state.activeId)
    updateModel(state.activeId, getNameById(dataList.value, state.activeId));
  } else {
    setStore({ name: 'libraryId', content: null })
    // emit("update:modelValue", null)
    updateModel(null, null)
  }
  window.addEventListener('setItemEvent', function (e) {
    nextTick(() => {
      if (e.key === 'libraryList') {
        dataList.value = useStore.getLibraryList()
      }
    })

  })
})

const getNameById = (arr, id) => {
  for (const item of arr) {
    if(item.libraryId == id){
      return item.libraryName;
    }
  }
}

const state = ({
  activeId: Number(props.modelValue)
})
const dataList = computed(() => useStore.getLibraryList)
const handleClick = (tab) => {
  setStore({ name: 'libraryId', content: tab.props.name })
  setStore({ name: 'libraryName', content: tab.props.label })
  // emit("update:modelValue", tab.props.name)
  updateModel(tab.props.name, tab.props.label)
  emit('handleClick', tab.props)

}

const updateModel = (id, name)=>{
  emit("update:modelValue", id)
  emit("update:organizationName", name)
}

defineExpose({})

const { activeId, show } = toRefs(state)
</script>
<style>
.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  position: relative;
  background-color: #fff;
}
</style>
<style lang="scss" scoped>
.switch-tabs {
  :deep(.el-tabs__header) {
    margin: 0 0 20px;
  }
  :deep(.el-tabs__item) {
    color: #000000a6;
    padding: 0px 13px;
  }
  :deep(
      .el-tabs--top.el-tabs--card
        > .el-tabs__header
        .el-tabs__item:nth-child(2n)
    ) {
    padding-left: 13px;
  }
  :deep(
      .el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:last-child
    ) {
    padding-right: 13px;
  }
  :deep(.el-tabs--card > .el-tabs__header .el-tabs__item.is-active) {
    color: var(--color-primary);
    font-weight: 500;
    border-radius: 8px 0px 0 0;
    background: #fff;
  }
  :deep(
      .el-tabs--card > .el-tabs__header .el-tabs__item.is-active:nth-child(1)
    ) {
    border-radius: 8px 0px 0 0 !important;
  }
  :deep(
      .el-tabs--card > .el-tabs__header .el-tabs__item.is-active:nth-child(1n)
    ) {
    border-radius: 0px 8px 0 0;
  }
  :deep(.el-tabs--card > .el-tabs__header .el-tabs__item) {
  }
  :deep(.el-tabs--card > .el-tabs__header .el-tabs__nav) {
    border-radius: 8px 8px 0 0;
  }
}
.custom-tabs-label {
  display: flex;
  align-items: center;
  img {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
}
</style>