/*
 * @Description:路由入口
 * @Author: zjc
 * @Date: 2021-12-07 14:10:31
 * @LastEditTime: 2024-08-27 14:38:07
 * @LastEditors: 杨明 ym@geeboo.cn
 */
import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import index from '@/views/layout/index.vue'
import useRouteCache from '@/hooks/useRouteCache'

const {
  addCache,
  removeCache,
  clearCache
} = useRouteCache()
import {
  mobileRoute
} from '@/routerMobile/index.js'
import {
  cacheRouter,
  noCacheRouter
} from '@/utils/cacheRouter'
import {
  nextTick
} from 'vue'

export const asyncRouteMap = [
  //权限管理
  {
    path: '/oms',
    component: index,
    children: []
  },
]
// 默认路由
export const staticRoute = [{
    path: '/oms/dashboard',
    component: index,
    children: [{
        path: '/oms/dashboard',
        component: () => import('@/views/index/index.vue'),
        name: '首页',
      },
      {
        path: '/transfer',
        component: () => import('@/views/layout/transfer.vue'),
        name: '中转',
      },

    ]
  },
  {
    path: '/404',
    component: () => import('@/views/404/404.vue'),
    name: '404'
  },
  // { path: '/no-permission', component: () => import('@/views/noPermission/noPermission.vue'), name: '暂无权限' },//暂无权限
  {
    path: '/',
    component: () => import('@/views/login/index.vue'),
    name: '登录'
  }, //登录
  {
    path: '/forgot-password',
    component: () => import('@/views/login/forgot-password.vue'),
    name: '找回密码'
  }, //找回密码
  {
    path: '/select-org',
    component: () => import('@/views/login/select-org.vue'),
    name: '选择组织'
  }, //选择组织
  {
    path: '/invite-library',
    component: () => import('@/views/login/invite-library.vue'),
    name: '邀请加入图书馆'
  }, //邀请加入图书馆
  {
    path: '/download-library',
    component: () => import('@/views/login/download-library.vue'),
    name: '云书馆'
  }, //云书馆下载页
  {
    path: '/text',
    component: () => import('@/views/login/text.vue'),
    name: '静态文本'
  }, //静态文本

  {
    path: '/public/invite',
    component: () => import('@/views/public/invite/index.vue'),
    name: '邀请注册'
  }, // 邀请注册

  // 移动端页面的路由
  ...mobileRoute,
]

const router = createRouter({
  routes: staticRoute,
  history: createWebHashHistory(),
})
let cache = true
let init = true
router.beforeEach((to, from, next) => {

  if (to.meta.title) { //h5积分商城的缓存
    const {
      meta,
      name
    } = to;
    const {
      keepAlive,
      routeLevel
    } = meta;
    const _componentName = name;
    if (keepAlive && _componentName) {
      addCache(_componentName);
    }
    // 返回积分首页的，直接清空缓存
    if (name === 'integral') {
      clearCache();
    } else {
      const _routeLevel = routeLevel;
      removeCacheRoute(from, _routeLevel);
    }
  } else { //pc端的缓存
    if (cacheRouter && cacheRouter.length) { //给路由添加缓存
      let index = cacheRouter.findIndex(i => i.url == to.path)
      if (index > -1) { //进入要缓存的页面
        to.meta.isKeepAlive = init? true: cache
        init = false
      } else { //离开要缓存页面
          let j = cacheRouter.findIndex(i => i.url == from.path)
          let a = noCacheRouter.findIndex(i => i.url == to.path)
          if (j > -1 && a > -1 && cacheRouter[j].id == noCacheRouter[a].parentId) {
            cache = true
          } else {
            cache = false
          }
      }
    }
    if(to.path == "/" ) { //退出登录重置状态
      init = true
    }
  }
  next();
})
// 删除缓存
const removeCacheRoute = (from, toLevel) => {
  const {
    meta
  } = from;
  const {
    keepAlive,
    componentName,
    routeLevel
  } = meta;
  const _componentName = componentName;
  const _fromLevel = routeLevel;
  if (keepAlive && _componentName && _fromLevel > toLevel) {
    removeCache(_componentName);
  }
}

export default router